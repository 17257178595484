import React from "react";

import styles from "./styles.module.css";

const categories = [
  "Fragnance",
  "Makeup",
  "Skin Care",
  "Hair",
  "Bath & Body",
  "Wellness",
];

const Categories = () => {
  return (
    <div className={styles.categories}>
      <ul className={styles.categories_list}>
        {categories.map((category, index) => (
          <li key={index} className={styles.category_item}>
            {category}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Categories;
