import React from "react";
import Image from "../../../../common/components/Image";
import globe_icon from "../../../../assets/globe.svg";
import hand_shake from "../../../../assets/hand_shake.svg";
import comfortable from "../../../../assets/comfortable.svg";

import ReferralForm from "./Referral";
import styles from "./styles.module.css";
import Vision from "./Vision";
import Values from "./Values";
import Individuality from "./Individuality";

function Body({ refObj }) {
  return (
    <div className={styles.container}>
      <div className={styles.hero_banner}>
        <Image
          src="https://images.squarespace-cdn.com/content/v1/61e79751fd40e431ea602dee/53c092f3-49f0-480b-a914-c137f1eb7012/glamzy.gif"
          width="100%"
        />
      </div>

      <div className={styles.tag_container}>
        <div className={styles.broad_label}>
          <div>Where Beauty and Wellness </div>
          <div>Embrace Every Shade of You.</div>
        </div>

        <div className={styles.strip}>
          <div className={styles.short_label}>
            <div>
              <Image src={globe_icon} height={20} width={20} />
            </div>
            Long-lasting and sustainable
          </div>

          <div className={styles.short_label}>
            <div>
              <Image src={hand_shake} height={20} width={20} />
            </div>
            Lovingly crafted for all bodies
          </div>
          <div className={styles.short_label}>
            <div>
              <Image src={comfortable} height={20} width={20} />
            </div>
            Unbeatably comfortable
          </div>
        </div>

        <Image
          src="https://images.squarespace-cdn.com/content/v1/61e79751fd40e431ea602dee/48909e14-9c9a-4625-a586-ef799eb6d77e/NEW+DRAFT-06.jpg"
          width="100%"
        />

        <Image
          src="https://images.squarespace-cdn.com/content/v1/61e79751fd40e431ea602dee/48a3c7bd-4899-457f-8b7e-96d40a7d722b/NEW+DRAFT-07.gif"
          width="100%"
        />

        <Image
          src="https://images.squarespace-cdn.com/content/v1/61e79751fd40e431ea602dee/c1b92d2c-868f-4864-92a5-770faf0e7edb/Text+GIF.gif"
          width="100%"
        />

        <ReferralForm refObj={refObj} />

        <Image
          src="https://images.squarespace-cdn.com/content/v1/61e79751fd40e431ea602dee/c7845f5d-31fb-4656-847d-8e04c0caa892/NEW+DRAFT-09.jpg"
          width="100%"
        />

        <Image
          src="https://images.squarespace-cdn.com/content/v1/61e79751fd40e431ea602dee/322f3e8e-dba3-4c5f-8cad-807464760309/NEW+DRAFT-12.jpg"
          width="100%"
        />

        <Image
          src="https://images.squarespace-cdn.com/content/v1/61e79751fd40e431ea602dee/b6d24f72-f731-40c8-87c7-d47ee4cd8c19/NEW+DRAFT.png"
          width="100%"
        />

        <Image
          src="https://images.squarespace-cdn.com/content/v1/61e79751fd40e431ea602dee/927c3e3e-637d-4899-b54e-8fe1e2e0207b/NEW+DRAFT-03.gif"
          width="100%"
        />

        <Vision />

        <Values />

        <Individuality />
      </div>
    </div>
  );
}

export default Body;
