import React, { useRef } from "react";
import Header from "./components/Header";
import Body from "./components/Body";
import Footer from "./components/Footer";

function Home() {
  const ref = useRef(null);

  return (
    <main>
      <Header refObj={ref} />
      <Body refObj={ref} />
      <Footer />
    </main>
  );
}

export default Home;
