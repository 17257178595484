import React, { useEffect, useState } from "react";

const PrivacyPolicy = () => {
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    // Fetch the HTML content from the S3 URL
    const fetchHtmlContent = async () => {
      try {
        const response = await fetch(
          "https://be-ui-uploads.s3.ap-south-1.amazonaws.com/production/38f96c2d9269440acbd0ce3bf269e8a1/glamzy_privacy_policy.html"
        );

        console.log(response, "response");
        const html = await response.text();
        setHtmlContent(html);
      } catch (error) {
        console.error("Error fetching HTML content:", error);
      }
    };

    fetchHtmlContent();
  }, []);

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default PrivacyPolicy;
