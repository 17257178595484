import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/glamzy_logo.png";
import Image from "../../../../common/components/Image";
import Categories from "./Categories";

import styles from "./styles.module.css";

const Header = ({ refObj }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleClick = () => {
    refObj.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <header className={styles.header}>
      <nav className={styles.nav}>
        <div className={styles.logo}>
          <Image src={logo} alt="logo" width={149} height={48} />
        </div>
        <ul className={styles.nav_list}>
          <li>
            <a href="/" className={styles.nav_link}>
              Home
            </a>
          </li>

          <li
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={styles.nav_item}
          >
            <div className={styles.nav_link}>Categories</div>
            {isHovering && <Categories />}
          </li>

          <li>
            <div className={styles.nav_link} onClick={handleClick}>
              Referral
            </div>
          </li>

          <li>
            <div className={styles.nav_link}>Contact Us</div>
          </li>

          <li>
            <Link to="/privacy-policy" className={styles.nav_link}>
              Privacy Policy
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
