import React from "react";

import styles from "./styles.module.css";

const TAGS = [
  {
    icon: "https://adn-static2.nykaa.com/media/wysiwyg/2021/Free-shipping.svg",
    label: "FAST SHIPPING",
    sub_label: "Shipped within 3 days",
  },
  {
    icon: "https://adn-static2.nykaa.com/media/wysiwyg/2021/return_accepted.svg",
    label: "EASY RETURNS",
    sub_label: "7day Return Policy",
  },
  {
    icon: "https://adn-static2.nykaa.com/media/wysiwyg/2021/Authenticity.svg",
    label: "100% ATHENTIC",
    sub_label: "Products Sourced Directly",
  },
  {
    icon: "https://adn-static2.nykaa.com/media/wysiwyg/2021/Brands.svg",
    label: "100+ Brands",
    sub_label: "200+ SKU's",
  },
];

function Footer() {
  return (
    <div className={styles.container}>
      <div className={styles.tags_container}>
        {TAGS.map((item, index) => (
          <div className={styles.tags} key={index}>
            <img src={item?.icon} alt="Free Shipping" width={40} height={40} />

            <div>
              <div className={styles.broad_label}>{item.label}</div>
              <div className={styles.short_label}>{item.sub_label}</div>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.links}>
        <div>Terms & Conditions</div>
        <div>Shipping Policy</div>
        <div>Cancellation Policy</div>
        <div>Privacy Policy</div>
      </div>

      <div className={styles.last_strip}>
        <span>©</span>
        "2024" "BUYEAZZY All Rights Reserved."
      </div>
    </div>
  );
}

export default Footer;
