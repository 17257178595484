import React from "react";

import styles from "./styles.module.css";

const VALUES = [
  {
    label: "One stop shop",
    sub_label:
      "A 360° store with all kinds of beauty, personal care and wellness products under one roof",
  },
  {
    label: "Elevating Shopping Experience",
    sub_label:
      "Discover new favourites, explore trending products and have personalised and immersive shopping experiences.",
  },
  {
    label: "Meet every need",
    sub_label: "Comprehensive solutions for all beauty and wellness needs",
  },
  {
    label: "Assurance",
    sub_label: "Products by trusted partners that are authentic and reliable",
  },
];

const Values = () => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>Our Values</div>

      <div className={styles.sub_container}>
        {VALUES.map((item) => (
          <div className={styles.value_container}>
            <div className={styles.broad_label}>{item?.label}</div>
            <div className={styles.short_label}>{item?.sub_label}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Values;
