import React from "react";
import Image from "../../../../../common/components/Image";

import styles from "./styles.module.css";

function Individuality() {
  return (
    <div className={styles.container}>
      <div className={styles.sub_container}>
        <div className={styles.broad_label}>Celebrating Individuality</div>

        <p className={styles.short_label}>
          Traditional notions of beauty often overlook diverse needs and unique
          journeys, leaving many feeling marginalised or neglected. Glamzy
          cultivates a welcoming space that is dedicated to making people feel
          good about themselves. A space that celebrates everyone's uniqueness
          and commits to make products accessible to different needs, making
          beauty and wellness accessible for everyone.
        </p>
      </div>

      <div className={styles.img_container}>
        <Image
          src="https://plus.unsplash.com/premium_photo-1680111699917-c5d1553ecc13?q=80&w=2971&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          width={600}
          height={400}
        />
      </div>
    </div>
  );
}

export default Individuality;
