import { useState } from "react";

const ENDPOINT = process.env.REACT_APP_API_BASE_URL;

const useGetReferralCode = () => {
  const [referralCode, setReferralCode] = useState(null);

  const getCode = async (formData) => {
    const url = new URL(
      `${ENDPOINT}/express_delivery/get_glamzy_referral_code`
    );
    url.searchParams.append("name", formData.name);
    url.searchParams.append("mobile_number", formData.mobile);

    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Failed to fetch referral code");
      }

      const data = await response.json();
      setReferralCode(data?.data?.code);
    } catch (error) {
      console.error("Error fetching referral code:", error);
      setReferralCode(null);
    }
  };

  return {
    getCode,
    referralCode,
  };
};

export default useGetReferralCode;
