import React from "react";
import Image from "../../../../../common/components/Image";

import styles from "./styles.module.css";

function Vision() {
  return (
    <div className={styles.container}>
      <div className={styles.img_container}>
        <Image src="https://mamaearth.in/blog/wp-content/uploads/2022/11/unnamed-69.jpg" />
      </div>

      <div className={styles.sub_container}>
        <div className={styles.broad_label}>Our Vision</div>

        <p className={styles.short_label}>
          Our vision at Glamzy is to redefine beauty and wellness by creating an
          inclusive space that celebrates diversity and empowers individuals
          worldwide. We aim to inspire confidence and self expression in every
          customer. By promoting holistic well-being and sustainability
          practices, we strive to make beauty and wellness accessible, enjoyable
          and integral to daily routines, fostering a community where everyone
          feels valued and celebrated.
        </p>
      </div>
    </div>
  );
}

export default Vision;
