import React, { useState } from "react";
import useGetReferralCode from "./hooks/useGetReferralCode";
import styles from "./styles.module.css";

const ReferralForm = ({ refObj }) => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    mobile: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const { getCode, referralCode } = useGetReferralCode();

  const handleSubmit = async (e) => {
    e.preventDefault();

    let newErrors = {
      name: "",
      mobile: "",
    };
    let valid = true;

    if (formData.name.trim() === "") {
      newErrors.name = "Name is required";
      valid = false;
    }

    if (formData.mobile.trim() === "") {
      newErrors.mobile = "Mobile is required";
      valid = false;
    }

    // Set errors
    setErrors(newErrors);

    // If form is valid, submit data
    if (valid) {
      try {
        console.log("Form submitted:", formData);
        // Assuming getCode is an async function to fetch data
        const response = await getCode(formData);

        // Handle response as needed
        console.log("Response:", response);
      } catch (error) {
        console.error("Error submitting form:", error);
        // Handle error state if needed
      }
    }
  };

  return (
    <div className={styles.container} ref={refObj}>
      <div className={styles.label}>
        {referralCode ? "Your Referral Code is" : "Get Your Referral Code"}
      </div>
      {referralCode ? (
        referralCode
      ) : (
        <form onSubmit={handleSubmit}>
          <div className={styles.input_container}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              className={`${styles.input_field} ${errors.name && styles.error}`}
              placeholder="Enter Name here"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && (
              <div className={styles.error_message}>{errors.name}</div>
            )}
          </div>

          <div className={styles.input_container}>
            <label htmlFor="mobile">Mobile</label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              className={`${styles.input_field} ${
                errors.mobile && styles.error
              }`}
              placeholder="Enter Phone Number"
              value={formData.mobile}
              onChange={handleChange}
            />
            {errors.mobile && (
              <div className={styles.error_message}>{errors.mobile}</div>
            )}
          </div>

          <button
            type="submit"
            className={styles.submit_button}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default ReferralForm;
